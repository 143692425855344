<template>
  <transition name="sc-transition-fade" mode="out-in" appear>
    <div class="analytics-group-report mb-5 mx-md-5 mx-lg-auto">
      <h2 class="text-center mb-4 fw-normal">Group Analytics <strong>{{ groupName }}</strong></h2>

      <div ref="analyticsColumns" class="row"
           @vnode-mounted="analyticsColsMounted"
           @vnode-updated="analyticsColsUpdated"
           @vnode-unmounted="analyticsColsUnmounted">

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Summary</h5>
              <AnalyticsNoData :isLoading="groupReportSummaryLoading"/>

              <ul v-if="!groupReportSummaryLoading" class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4">{{ fmtInt(groupReportSummary.total_page_views) }}</strong>
                  </div>
                  Slide views
                </li>
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4">{{ fmtInt(groupReportSummary.total_resource_views) }}</strong>
                  </div>
                  File views
                </li>
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4">{{ fmtInt(groupReportSummary.total_shared_items) }}</strong>
                  </div>
                  Shared items
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-lg-3 mb-3">
                  <button type="button" class="btn"
                          @click.prevent="toggleShowSearchInput('user')"
                          :class="{'btn-outline-success' : searchToggles.user === false, 'btn-outline-danger' : searchToggles.user === true}">
                    {{ searchToggles.user === false ? 'Find a User' : 'Default view' }}
                  </button>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <h5 class="card-title text-center">Top users</h5>
                </div>
              </div>

              <div class="input-group mb-3" v-if="searchToggles.user === true">
                <ScSelectWithSearchAndGroups :items="[userList]"
                                             :placeholder="'Select user'"
                                             :emptyAfterSelection="false"
                                             :maxHeightClass="150"
                                             @sc-select-item="selectUser">
                </ScSelectWithSearchAndGroups>
              </div>

              <div class="d-flex justify-content-end">
                <div class="text-muted">Slide views</div>
              </div>

              <AnalyticsNoData :isLoading="groupReportSummaryLoading"/>
              <AnalyticsTopUsers v-if="groupReportSummary && !groupReportSummaryLoading"
                                 :csvNameAddition="groupName"
                                 topUsersCountCsvTitle="Slide Views"
                                 :hasOpenedCount="false"
                                 :topUsers="computedWorkshopTopUsers"
                                 @paginated="paginated"></AnalyticsTopUsers>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-lg-4 mb-3">
                  <button type="button" class="btn"
                          @click.prevent="toggleShowSearchInput('presentation')"
                          :class="{'btn-outline-success' : searchToggles.presentation === false, 'btn-outline-danger' : searchToggles.presentation === true}">
                    {{ searchToggles.presentation === false ? 'Find a Presentation' : 'Default View' }}
                  </button>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <h5 class="card-title text-center">Top presentations</h5>
                </div>
              </div>

              <div class="input-group mb-3" v-if="searchToggles.presentation === true">
                <ScSelectWithSearchAndGroups :items="[presentationList]"
                                             :placeholder="'Select presentation'"
                                             :emptyAfterSelection="false"
                                             :maxHeightClass="150"
                                             @sc-select-item="selectPresentation">
                </ScSelectWithSearchAndGroups>
              </div>

              <div class="d-flex justify-content-end">
                <div class="text-muted">Slide views</div>
              </div>

              <AnalyticsNoData :isLoading="groupReportSummaryLoading"/>
              <AnalyticsTopShowcases v-if="!groupReportSummaryLoading && groupReportSummary"
                                     :csvNameAddition="groupName"
                                     :topShowcases="computedWorkshopTopShowcases"
                                     @paginated="paginated"></AnalyticsTopShowcases>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Top files</h5>

              <div class="d-flex justify-content-end">
                <div class="text-muted">File views</div>
              </div>

              <AnalyticsNoData :isLoading="showcaseResourceViewsLoading"/>
              <AnalyticsTopFiles v-if="!showcaseResourceViewsLoading"
                                 :csvNameAddition="groupName"
                                 topFilesCountCsvTitle="Views"
                                 :topFiles="showcaseResourceViews"
                                 @paginated="paginated"></AnalyticsTopFiles>
            </div>
          </div>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>
    import $ from 'jquery';
    import Masonry from 'masonry-layout';
    import ScNotification from '../../../shared/common/ScNotification.vue';
    import AnalyticsMixin from './AnalyticsMixin';
    import AnalyticsNoData from './AnalyticsNoData.vue';
    import AnalyticsTopUsers from './AnalyticsTopUsers.vue';
    import AnalyticsTopShowcases from './AnalyticsTopShowcases.vue';
    import AnalyticsTopFiles from './AnalyticsTopFiles.vue';
    import ScSelectWithSearchAndGroups from "../../global/ScSelectWithSearchAndGroups.vue";
    import _ from "underscore";

    let _testNotEnoughData = false;

    let _getShowName = function(userObj) {
      let user = userObj.auth_user;
      if (userObj.displayable_name === user.email) return user.email;
      return userObj.displayable_name + ' <' + user.email + '>';
    };

    let DataManager = function() {
      return {
        getGroupSummary(ajaxParams, vm) {
          if (vm.groupReportSummaryLoading) return;
          vm.groupReportSummary = {};
          vm.groupReportSummaryLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_group_report",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData || data)
              vm.groupReportSummary = data;

          }).always(() => {
            vm.groupReportSummaryLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading group summary');
          });
        },
        getGroupTopResourceViews(ajaxParams, vm) {
          if (vm.showcaseResourceViewsLoading) return;
          vm.showcaseResourceViewsLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_group_resource_views",
            data: ajaxParams

          }).done((data) => {

            //console.log(data, 'DATA');
            if (!_testNotEnoughData || data || data.resource_views) {
              for (let res of data.resource_views) {
                vm.showcaseResourceViews.push(res);
              }
              vm.showcaseResourceViews.sort(function(a, b){ return b.count - a.count; });
            }

          }).always(() => {
            setTimeout(() => { vm.showcaseResourceViewsLoading = false; }, 1000);

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading group views');
          });
        },

        getWorkshopSpecificUser(ajaxParams, vm, userId) {
          vm.workshopUserListLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_workshop_specific_user_summary",
            data: { ...ajaxParams, userId: userId}

          }).done((data) => {
            if (!_testNotEnoughData && data && data.user)
              vm.selectedUser = data.user;

          }).always(() => {
            vm.workshopUserListLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading top users for workshop');
          });
        },

        getWorkshopTopSpecificShowcases(ajaxParams, vm, presentationId) {
          vm.workshopTopShowcasesLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_workshop_specific_showcase",
            data: { ...ajaxParams, presentationId: presentationId }

          }).done((data) => {
            if (!_testNotEnoughData && data && data.top_showcase)
              vm.selectedPresentation = data.top_showcase;

          }).always(() => {
            vm.workshopTopShowcasesLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading top presentations for workshop');
          });
        },

        getUserGroupAndPresentationList(ajaxParams, vm) {
          vm.workshopUserListLoading = true;
          vm.workshopTopShowcasesLoading = true;

          $.ajax({
            type: 'GET',
            url: '/main/analytics/ajax_get_user_group_presentation_list',
            data: ajaxParams
          }).done(function (data) {
            if (!_.isEmpty(data) && !_.isEmpty(data.all_users)) {
              for (let g in data.all_users) {
                let u = data.all_users[g];
                u.auth_user = u;
                u.showName = _getShowName(u);
                vm.userList.push(u);
              }
            }
            if (!_.isEmpty(data) && !_.isEmpty(data.all_presentations)) {
              for (let gr in data.all_presentations) {
                let p = data.all_presentations[gr];
                p.showName = p.name;
                vm.presentationList.push(p);
              }
            }
          }).always(() => {
            vm.workshopUserListLoading = false;
            vm.workshopTopShowcasesLoading = false;
          })
        },
      };

    };

    let _dataManager = null;

    export default {
      name: "AnalyticsForGroup",
      mixins: [ AnalyticsMixin ],
      components: {ScSelectWithSearchAndGroups, AnalyticsTopUsers, AnalyticsTopShowcases, AnalyticsTopFiles, AnalyticsNoData },
      props: {
        ajaxParams: {}
      },
      data () {
        return {
          groupReportSummary: {},
          groupReportSummaryLoading: false,
          showcaseResourceViews: [],
          showcaseResourceViewsLoading: false,

          searchToggles: {
            user: false,
            presentation: false,
          },
          searchValues: '',
          selectedUser: null,
          selectedPresentation: null,

          workshopUserListLoading: false,
          workshopTopShowcasesLoading: false,

          userList: [],
          presentationList: [],
        };
      },
      mounted () {
        _dataManager = new DataManager();
        this.loadData();

      },

      methods: {
        selectPresentation(presentation) {
          if (!this.selectedPresentation || this.selectedPresentation.id !== presentation.id) {
            _dataManager.getWorkshopTopSpecificShowcases(this.ajaxParams, this, presentation.id)
          }
        },
        selectUser(user) {
          if (!this.selectedUser || this.selectedUser.user_id !== user.id) {
            _dataManager.getWorkshopSpecificUser(this.ajaxParams, this, user.id);
          }
        },
        toggleShowSearchInput(card) {
          if (card !== 'user') {
            this.searchToggles['user'] = false;
          }
          if (card !== 'presentation') {
            this.searchToggles['presentation'] = false;
          }
          this.searchValues = '';
          this.searchToggles[card] = !this.searchToggles[card];

          this.selectedUser = null;
          this.selectedPresentation = null;
        },
        analyticsColsMounted () {
          this.$msnry = new Masonry( this.$refs.analyticsColumns, {
            itemSelector: '.analytics-grid-item',
            percentPosition: true
          });
        },
        analyticsColsUpdated () {
          if (this.$msnry) {
            this.$msnry.reloadItems();
            this.$msnry.layout();
          }
        },
        analyticsColsUnmounted () {
          if (this.$msnry) {
            this.$msnry.destroy();
            this.$msnry = null;
          }
        },
        paginated () { // triggered masonry update after pagination
          this.$nextTick(() => {
            this.analyticsColsUpdated();
          });
        },
        loadData () {
          _dataManager.getGroupSummary(this.ajaxParams, this);
          _dataManager.getGroupTopResourceViews(this.ajaxParams, this);
          _dataManager.getUserGroupAndPresentationList(this.ajaxParams, this);
        },
        getPagePercentageOfTotal (entry) {
          return Math.round((entry.count/this.groupReportSummary.total_page_views) *100);
        }
      },
      computed: {
        groupName () {
          return this.groupReportSummary.group_name;
        },
        computedWorkshopTopUsers () {
          return (this.selectedUser === null) ? this.groupReportSummary.top_users : [this.selectedUser];
        },
        computedWorkshopTopShowcases () {
          return (this.selectedPresentation === null) ? this.groupReportSummary.top_showcases : [this.selectedPresentation];
        },
      },
      watch: {
        ajaxParams () {
          this.loadData();
        }
      }
    }
</script>
