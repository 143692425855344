<template>
  <transition name="sc-transition-fade" mode="out-in" appear>
    <div class="analytics-workshop-report mb-5 mx-md-5 mx-lg-auto">
      <h2 class="text-center mb-4 fw-normal"><strong>Workshop</strong> Analytics</h2>

      <div ref="analyticsColumns" class="row"
           @vnode-mounted="analyticsColsMounted"
           @vnode-updated="analyticsColsUpdated"
           @vnode-unmounted="analyticsColsUnmounted">

        <AnalyticsEmptyTeaser v-if="showEmptyTeaser"/>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Summary</h5>
              <AnalyticsNoData :isLoading="workshopReportSummaryLoading"/>

              <ul v-if="!workshopReportSummaryLoading" class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4">{{ fmtInt(workshopReportSummary.page_views) }}</strong>
                  </div>
                  Slide views
                </li>
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4">{{ fmtInt(workshopReportSummary.resource_views) }}</strong>
                  </div>
                  File views
                </li>
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4">{{ fmtInt(workshopReportSummary.shared_items) }}</strong>
                  </div>
                  Shared items
                </li>
                <li class="list-group-item">
                  <button @click.prevent="exportSummaryCsv()" class="btn btn-link text-muted p-0"
                    v-sc-tooltip="'Export CSV file'"><ScIcon name="download"/> CSV</button>
                  <template v-if="userSummaryCsvDataLoading">
                    <ScIcon name="spinnerFW" class="mx-2 text-muted" />
                  </template>
                  <div class="csv-loading" v-if="userSummaryCsvDataLoading">CSV file download may take up to 60 seconds.</div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-lg-3 mb-3">
                  <button type="button" class="btn"
                          @click.prevent="toggleShowSearchInput('user')"
                          :class="{'btn-outline-success' : searchToggles.user === false, 'btn-outline-danger' : searchToggles.user === true}">
                    {{ searchToggles.user === false ? 'Find a User' : 'Default view' }}
                  </button>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <h5 class="card-title text-center">Top users</h5>
                </div>
              </div>

              <div class="input-group mb-3" v-if="searchToggles.user === true">
                <ScSelectWithSearchAndGroups :items="[userList]"
                                             :placeholder="'Select user'"
                                             :emptyAfterSelection="false"
                                             :maxHeightClass="150"
                                             @sc-select-item="selectUser">
                </ScSelectWithSearchAndGroups>
              </div>

              <div class="d-flex justify-content-end">
                <div class="text-muted">Slide views</div>
              </div>

              <AnalyticsNoData :isLoading="workshopTopUsersLoading && workshopUserListLoading"/>
              <AnalyticsTopUsers
                  v-if="!workshopTopUsersLoading"
                  topUsersCountCsvTitle="Slide Views"
                  :hasOpenedCount="false"
                  :topUsers="computedWorkshopTopUsers"
                  @paginated="paginated"></AnalyticsTopUsers>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Slide views by day</h5>

              <AnalyticsNoData :isLoading="workshopSlideViewsByDayLoading" :noData="!workshopSlideViewsByDayHasValues"/>

              <line-chart v-if="!workshopSlideViewsByDayLoading && workshopSlideViewsByDayHasValues"
                          :data="chartData" :height="200"></line-chart>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-lg-3 mb-3">
                  <button type="button" class="btn"
                          @click.prevent="toggleShowSearchInput('group')"
                          :class="{'btn-outline-success' : searchToggles.group === false, 'btn-outline-danger' : searchToggles.group === true}">
                    {{ searchToggles.group === false ? 'Find a Group' : 'Default View' }}
                  </button>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <h5 class="card-title text-center">Top groups</h5>
                </div>
              </div>

              <div class="input-group mb-3" v-if="searchToggles.group === true">
                <ScSelectWithSearchAndGroups :items="[groupList]"
                                             :placeholder="'Select group'"
                                             :emptyAfterSelection="false"
                                             :maxHeightClass="150"
                                             @sc-select-item="selectGroup">
                </ScSelectWithSearchAndGroups>
              </div>

              <div class="d-flex justify-content-end">
                <div class="text-muted">Slide views</div>
              </div>

              <AnalyticsNoData :isLoading="workshopTopGroupsLoading"/>
              <AnalyticsTopGroups
                  v-if="!workshopTopGroupsLoading"
                  :workshopTopGroups="computedWorkshopTopGroups"
                  @paginated="paginated"></AnalyticsTopGroups>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-lg-4 mb-3">
                  <button type="button" class="btn"
                          @click.prevent="toggleShowSearchInput('presentation')"
                          :class="{'btn-outline-success' : searchToggles.presentation === false, 'btn-outline-danger' : searchToggles.presentation === true}">
                    {{ searchToggles.presentation === false ? 'Find a Presentation' : 'Default View' }}
                  </button>
                </div>
                <div class="col-12 col-lg-6 mb-3">
                  <h5 class="card-title text-center">Top presentations</h5>
                </div>
              </div>

              <div class="input-group mb-3" v-if="searchToggles.presentation === true">
                <ScSelectWithSearchAndGroups :items="[presentationList]"
                                             :placeholder="'Select presentation'"
                                             :emptyAfterSelection="false"
                                             :maxHeightClass="150"
                                             @sc-select-item="selectPresentation">
                </ScSelectWithSearchAndGroups>
              </div>

              <div class="d-flex justify-content-end">
                <div class="text-muted">Slide views</div>
              </div>

              <AnalyticsNoData :isLoading="workshopTopShowcasesLoading"/>
              <AnalyticsTopShowcases
                  v-if="!workshopTopShowcasesLoading"
                  :topShowcases="computedWorkshopTopShowcases"
                  @paginated="paginated"></AnalyticsTopShowcases>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6">
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="card-title text-center">Top devices</h5>
              <div class="d-flex justify-content-end">
                <div class="text-muted">Slide views</div>
              </div>

              <AnalyticsNoData :isLoading="workshopTopDevicesLoading"/>
              <AnalyticsTopDevices
                  v-if="!workshopTopDevicesLoading"
                  :workshopTopDevices="workshopTopDevices"
                  @paginated="paginated"></AnalyticsTopDevices>
            </div>
          </div>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>
    import $ from 'jquery';
    import Masonry from 'masonry-layout';
    import LineChart from './LineChart.vue';
    import ScNotification from '../../../shared/common/ScNotification.vue';
    import AnalyticsMixin from './AnalyticsMixin';
    import AnalyticsNoData from './AnalyticsNoData.vue';
    import AnalyticsTopShowcases from './AnalyticsTopShowcases.vue';
    import AnalyticsTopUsers from './AnalyticsTopUsers.vue';
    import AnalyticsTopGroups from './AnalyticsTopGroups.vue';
    import AnalyticsTopDevices from './AnalyticsTopDevices.vue';
    import AnalyticsEmptyTeaser from "./AnalyticsEmptyTeaser.vue";
    import ScIcon from "@/shared/common/ScIcon.vue";
    import _ from "underscore";
    import ScSelectWithSearchAndGroups from "../../global/ScSelectWithSearchAndGroups.vue";

    let _testNotEnoughData = false;

    let _getShowName = function(userObj) {
      let user = userObj.auth_user;
      if (userObj.displayable_name === user.email) return user.email;
      return userObj.displayable_name + ' <' + user.email + '>';
    };

    let DataManager = function() {
      return {
        getWorkshopSummary(ajaxParams, vm) {
          if (vm.workshopReportSummaryLoading) return;
          vm.workshopReportSummary = {};
          vm.workshopReportSummaryLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_workshop_summary",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData && data)
              vm.workshopReportSummary = data;

          }).always(() => {
            vm.workshopReportSummaryLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading workshop summary');
          });
        },

        getWorkshopSlideViewsByDay(ajaxParams, vm) {
          if (vm.workshopSlideViewsByDayLoading) return;
          vm.workshopSlideViewsByDay.splice(0);
          vm.workshopSlideViewsByDayLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_workshop_total_views",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData && data && data.total_views)
              vm.workshopSlideViewsByDay = data.total_views;

          }).always(() => {
            vm.workshopSlideViewsByDayLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading slide views for workshop');
          });
        },

        getWorkshopTopUsers(ajaxParams, vm) {
          if (vm.workshopTopUsersLoading) return;
          vm.workshopTopUsers.splice(0);
          vm.workshopTopUsersLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_workshop_user_summary",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData && data && data.users)
              vm.workshopTopUsers = data.users;

          }).always(() => {
            vm.workshopTopUsersLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading top users for workshop');
          });
        },

        getWorkshopSpecificUser(ajaxParams, vm, userId) {
          if (vm.workshopUserListLoading) return;
          vm.workshopUserListLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_workshop_specific_user_summary",
            data: { ...ajaxParams, userId: userId}

          }).done((data) => {
            if (!_testNotEnoughData && data && data.user)
              vm.selectedUser = data.user;

          }).always(() => {
            vm.workshopUserListLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading top users for workshop');
          });
        },

        getWorkshopSpecificGroup(ajaxParams, vm, groupId) {
          if (vm.workshopTopGroupsLoading) return;
          vm.workshopTopGroupsLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_workshop_specific_group_summary",
            data: { ...ajaxParams, groupId: groupId}

          }).done((data) => {
            if (!_testNotEnoughData && data && data.group)
              vm.selectedGroup = data.group;

          }).always(() => {
            vm.workshopTopGroupsLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading top users for workshop');
          });
        },

        getWorkshopTopGroups(ajaxParams, vm) {
          if (vm.workshopTopGroupsLoading) return;
          vm.workshopTopGroups.splice(0);
          vm.workshopTopGroupsLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_workshop_group_summary",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData && data && data.groups)
              vm.workshopTopGroups = data.groups;

          }).always(() => {
            vm.workshopTopGroupsLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading top groups for workshop');
          });
        },

        getWorkshopTopShowcases(ajaxParams, vm) {
          if (vm.workshopTopShowcasesLoading) return;
          vm.workshopTopShowcases.splice(0);
          vm.workshopTopShowcasesLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_workshop_top_showcases",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData && data && data.top_showcases)
              vm.workshopTopShowcases = data.top_showcases;

          }).always(() => {
            vm.workshopTopShowcasesLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading top presentations for workshop');
          });
        },

        getWorkshopTopSpecificShowcases(ajaxParams, vm, presentationId) {
          vm.workshopTopShowcasesLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_workshop_specific_showcase",
            data: { ...ajaxParams, presentationId: presentationId }

          }).done((data) => {
            if (!_testNotEnoughData && data && data.top_showcase)
              vm.selectedPresentation = data.top_showcase;

          }).always(() => {
            vm.workshopTopShowcasesLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading top presentations for workshop');
          });
        },

        getWorkshopTopDevices(ajaxParams, vm) {
          if (vm.workshopTopDevicesLoading) return;
          vm.workshopTopDevices.splice(0);
          vm.workshopTopDevicesLoading = true;

          $.ajax({
            url: "/main/analytics/ajax_get_workshop_top_devices",
            data: ajaxParams

          }).done((data) => {
            if (!_testNotEnoughData && data && data.top_devices)
              vm.workshopTopDevices = data.top_devices;

          }).always(() => {
            vm.workshopTopDevicesLoading = false;

          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading top devices for workshop');
          });
        },

        getUserGroupAndPresentationList(ajaxParams, vm) {
          vm.workshopUserListLoading = true;
          vm.workshopTopGroupsLoading = true;
          vm.workshopTopShowcasesLoading = true;

          $.ajax({
            type: 'GET',
            url: '/main/analytics/ajax_get_user_group_presentation_list',
            data: ajaxParams
          }).done(function (data) {
            if (!_.isEmpty(data) && !_.isEmpty(data.all_users)) {
              for (let g in data.all_users) {
                let u = data.all_users[g];
                u.auth_user = u;
                u.showName = _getShowName(u);
                vm.userList.push(u);
              }
            }
            if (!_.isEmpty(data) && !_.isEmpty(data.all_groups)) {
              for (let gr in data.all_groups) {
                let g = data.all_groups[gr];
                g.showName = g.name;
                vm.groupList.push(g);
              }
            }
            if (!_.isEmpty(data) && !_.isEmpty(data.all_presentations)) {
              for (let gr in data.all_presentations) {
                let p = data.all_presentations[gr];
                p.showName = p.name;
                vm.presentationList.push(p);
              }
            }
          }).always(() => {
            vm.workshopUserListLoading = false;
            vm.workshopTopGroupsLoading = false;
            vm.workshopTopShowcasesLoading = false;
          })
        },
      }
    };
    let _dataManager = null;

    export default {
      name: "AnalyticsForWorkshop",
      mixins: [ AnalyticsMixin ],
      components: {
        ScSelectWithSearchAndGroups,
        ScIcon, AnalyticsTopShowcases, AnalyticsTopUsers, AnalyticsTopGroups, AnalyticsTopDevices, LineChart,
        AnalyticsNoData, AnalyticsEmptyTeaser },
      props: {
        ajaxParams: {}
      },
      data () {
        return {
          testCounter: 0,
          workshopReportSummary: {},
          workshopReportSummaryLoading: false,
          workshopSlideViewsByDay: [],
          workshopSlideViewsByDayLoading: false,
          workshopSlideViewsByDayHasValues: false,
          workshopTopUsers: [],
          workshopTopUsersLoading: false,
          workshopTopGroups: [],
          workshopTopGroupsLoading: false,
          workshopTopShowcases: [],
          workshopTopShowcasesLoading: false,
          workshopTopDevices: [],
          workshopTopDevicesLoading: false,
          userSummaryCsvDataLoading: false,

          searchToggles: {
            user: false,
            group: false,
            presentation: false,
          },
          searchValues: '',

          workshopUserListLoading: false,
          userList: [],
          groupList: [],
          presentationList: [],

          selectedUser: null,
          selectedGroup: null,
          selectedPresentation: null,

          /* vue-chartjs */
          chartData: {
            labels: [],
            datasets: [{
              scChartTooltipPrefix: 'Slide views',
              backgroundColor: 'transparent',
              lineTension: 0,
              borderColor: '#008ab0',
              pointBackgroundColor: '#008ab0',
              pointRadius: 4,
              pointHoverRadius: 5,
              pointHoverBorderColor: 'rgba(0, 128, 163, .5)',
              data: []
            }]
          }
        };
      },
      mounted () {
        _dataManager = new DataManager();
        this.loadData();

        setInterval(() => {
          this.testCounter++;
        }, 5000);
      },

      methods: {
        selectPresentation(presentation) {
          if (!this.selectedPresentation || this.selectedPresentation.id !== presentation.id) {
            _dataManager.getWorkshopTopSpecificShowcases(this.ajaxParams, this, presentation.id)
          }
        },

        selectGroup(group) {
          if (!this.selectedGroup || this.selectedGroup.group_id !== group.id) {
            _dataManager.getWorkshopSpecificGroup(this.ajaxParams, this, group.id)
          }
        },

        selectUser(user) {
          if (!this.selectedUser || this.selectedUser.user_id !== user.id) {
            _dataManager.getWorkshopSpecificUser(this.ajaxParams, this, user.id);
          }
        },

        toggleShowSearchInput(card) {
          if (card !== 'user') {
            this.searchToggles['user'] = false;
          }
          if (card !== 'group') {
            this.searchToggles['group'] = false;
          }
          if (card !== 'presentation') {
            this.searchToggles['presentation'] = false;
          }
          this.searchValues = '';
          this.searchToggles[card] = !this.searchToggles[card];

          this.selectedUser = null;
          this.selectedGroup = null;
          this.selectedPresentation = null;
        },

        analyticsColsMounted () {
          this.$msnry = new Masonry( this.$refs.analyticsColumns, {
            itemSelector: '.analytics-grid-item',
            percentPosition: true
          });
        },
        analyticsColsUpdated () {
          if (this.$msnry) {
            this.$msnry.reloadItems();
            this.$msnry.layout();
          }
        },
        analyticsColsUnmounted () {
          if (this.$msnry) {
            this.$msnry.destroy();
            this.$msnry = null;
          }
        },
        paginated () { // triggered masonry update after pagination
          this.$nextTick(() => {
            this.analyticsColsUpdated();
          });
        },
        loadData () {
          _dataManager.getWorkshopSummary(this.ajaxParams, this);
          _dataManager.getWorkshopSlideViewsByDay(this.ajaxParams, this);
          _dataManager.getWorkshopTopUsers(this.ajaxParams, this);
          _dataManager.getWorkshopTopGroups(this.ajaxParams, this);
          _dataManager.getWorkshopTopShowcases(this.ajaxParams, this);
          _dataManager.getWorkshopTopDevices(this.ajaxParams, this);
          _dataManager.getUserGroupAndPresentationList(this.ajaxParams, this);
        },
        exportSummaryCsv() {
          if (this.userSummaryCsvDataLoading) {
            return;
          }

          const startDate = this.ajaxParams.startDate;
          const endDate = this.ajaxParams.endDate;
          const now  = new Date();
          if (this.isDateRangeGreaterThanDays(endDate, now, 180)) {
            ScNotification.growlErrMsg('The selected date must be within 180 days');
            return;
          }

          const endDateTmp = new Date(endDate);
          endDateTmp.setMonth(endDateTmp.getMonth() - 3);
          if (endDateTmp.getTime() > new Date(startDate).getTime()) {
            ScNotification.growlErrMsg('This report can only be downloaded within a 3 month range.');
            return;
          }

          this.userSummaryCsvDataLoading = true;
          $.ajax({
            url: "/main/analytics/ajax_get_user_summary_csv_data",
            data: this.ajaxParams
          }).done((data) => {
            let csvLinesArr = [];
            for (let entry of data.workshop_user_views) {
              csvLinesArr.push([
                entry.username,
                entry.presentation_name,
                entry.page_count,
                entry.open_num
              ]);
            }

            let csvName = 'workshop-presentations';
            this.exportCsv(csvName, ['user', 'presentation', 'number of slides viewed', 'number of opens(more than 4s)'], csvLinesArr);
            this.userSummaryCsvDataLoading = false;
          }).fail((jqXhr) => {
            ScNotification.growlXhrError(jqXhr, 'loading csv data error');
          });
        },
        isDateRangeGreaterThanDays(startDate, endDate, days) {
          const start = new Date(startDate);
          const end = new Date(endDate);

          const timeDifference = end - start;
          const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
          return dayDifference > days;
        },
      },
      computed: {
        isSummaryEmpty () {
          return (parseInt(this.workshopReportSummary.page_views)
              + parseInt(this.workshopReportSummary.resource_views)
              + parseInt(this.workshopReportSummary.shared_items)) === 0
        },
        showEmptyTeaser () {
          if (this.workshopReportSummaryLoading) return false;
          return this.isDefaultDateRange(this.ajaxParams.startDate, this.ajaxParams.endDate) && this.isSummaryEmpty;
        },
        computedWorkshopTopUsers () {
          return (this.selectedUser === null) ? this.workshopTopUsers : [this.selectedUser];
        },
        computedWorkshopTopGroups () {
          return (this.selectedGroup === null) ? this.workshopTopGroups : [this.selectedGroup];
        },
        computedWorkshopTopShowcases () {
          return (this.selectedPresentation === null) ? this.workshopTopShowcases : [this.selectedPresentation];
        }
      },
      watch: {
        workshopSlideViewsByDay (nv) {
          if (nv.length === 0)
            return;

          /* vue-chartjs */
          this.chartData.labels.splice(0);
          this.chartData.datasets[0].data.splice(0);
          this.workshopSlideViewsByDayHasValues = false;

          for (let entry of nv) {
            this.chartData.labels.push(entry['event_day']);
            this.chartData.datasets[0].data.push(Number(entry['count']));
            if (Number(entry['count']) > 0) this.workshopSlideViewsByDayHasValues = true;
          }
        },
        ajaxParams () {
          this.loadData();
        }
      },
    }
</script>

<style>

    svg {
      overflow: initial !important;
    }

    .csv-loading {
      color: red;
      font-size: 8px;
    }
</style>
